/* eslint-enable */
/* eslint-disable */

// Burger functionality

const burgerButton = document.querySelector('#burger-button');
const burgerMenu = document.querySelector('#header-burger-menu');
let canvasWidth, canvasHeight;

burgerButton.addEventListener('click', toggleBurgerMenu)

let isBurgerOpened = false

function closeBurger() {
  gsap.to('#burger-button-open', {
    opacity: 1,
    duration: 0.8,
  })

  gsap.to('#burger-button-close', {
    opacity: 0,
    duration: 0.8,
  })

  gsap.to(burgerMenu, {
    top: '-150%',
    duration: 0.8,
    ease: 'linear',
    delay: 0.5
  })

  gsap.to(burgerMenu.querySelector(".header-burger-menu__background"), {
    backgroundColor: 'transparent',
    duration: 0.5,
    ease: 'linear',
  })

  document.removeEventListener('click', closeBurger);
}


function toggleBurgerMenu() {
  if (!isBurgerOpened) {
    setTimeout(() => {
      document.addEventListener('click', closeBurger)
    }, 1000);

    gsap.to('#burger-button-open', {
      opacity: 0,
      duration: 0.8,
    })

    gsap.to('#burger-button-close', {
      opacity: 1,
      duration: 0.8,
    })

    gsap.to(burgerMenu, {
      top: 0,
      duration: 0.8,
      ease: 'linear'
    })


    gsap.to(burgerMenu.querySelector(".header-burger-menu__background"), {
      backgroundColor: '#000000',
      duration: 0.5,
      ease: 'linear',
      delay: 0.8
    })
  }

  isBurgerOpened = !isBurgerOpened
}

const mainHeaderContact = document.querySelector('.header__contact')

ScrollTrigger.create({
    trigger: ".main-block",
    start: "top top",
    // end: "bottom top",
    onToggle: (self) => {
        const scrollY = window.scrollY;
        const contactBlock = document.getElementById("main-block__contact--large");
        const contactBlockSmall = document.getElementById("main-block__contact--small");

        if (scrollY < 200) {
            gsap.to(contactBlock, {
                opacity: 0,
                duration: 0.3
            })

            gsap.to(contactBlockSmall, {
                height: 0,
            })

            gsap.to(mainHeaderContact, {
                opacity: 1,
                duration: 0.3
            })

            gsap.to('.header', {
                backgroundColor: 'rgba(255, 255, 255,1)',
            })

            gsap.to('#header__main-logo', {
                opacity: 1
            })

            gsap.to('#header__main-logo-transparent', {
                opacity: 0
            })
        } else {
            gsap.to(contactBlock, {
                opacity: 1,
                duration: 0.3
            })

            gsap.to(contactBlockSmall, {
                height: 'auto',
            })

            gsap.to(mainHeaderContact, {
                opacity: 0,
                duration: 0.3
            })

            gsap.to('.header', {
                backgroundColor: 'rgba(255, 255, 255,0.5)',
            })

            gsap.to('#header__main-logo', {
                opacity: 0
            })

            gsap.to('#header__main-logo-transparent', {
                opacity: 1
            })
        }
    },
});


const checkScroll = () => {
    const scrollY = window.scrollY;
    const contactBlock = document.getElementById("main-block__contact--large");
    if (scrollY >= 200) {
        gsap.to(contactBlock, {
            opacity: 1,
            duration: 0.3
        })

        // gsap.to(contactBlockSmall, {
        //     height: 'auto',
        // })

        gsap.to(mainHeaderContact, {
            opacity: 0,
            duration: 0.3
        })

        gsap.to('.header', {
            backgroundColor: 'rgba(255, 255, 255,0.5)',
        })

        gsap.to('#header__main-logo', {
            opacity: 0
        })

        gsap.to('#header__main-logo-transparent', {
            opacity: 1
        })
    }

    requestAnimationFrame(checkScroll)
}

checkScroll()

ScrollTrigger.refresh()

const sliderItemsImages = document.querySelectorAll(".main-block__slider-slide")
const mainSliderAnimationTL = gsap.timeline({ repeat: -1 })


sliderItemsImages.forEach((slide, index) => {
    gsap.set(slide, { opacity: index === 0 ? 1 : 0 });
});

sliderItemsImages.forEach((slide, index) => {
    const nextSlide = sliderItemsImages[(index + 1) % sliderItemsImages.length];

    mainSliderAnimationTL.to(slide, {
        opacity: 0,
        duration: 0.85,
        ease: "power1.inOut"
    }, '>+0.5')

    mainSliderAnimationTL.to(nextSlide, {
        opacity: 1,
        duration: 0.85,
        ease: "power1.inOut"
    }, '<');
});

// Start animation

let startAnimationSvg = document.querySelector('.main-block__animation')
let mainBlock = document.querySelector('.main-block')
let mainBlockWrapper = document.querySelector('.main-block__wrapper')
let mainBlockVideo = document.querySelector('.main-block__slider')
let content = document.querySelector('.content')
let body = document.querySelector('body')


let startTl = gsap.timeline({ delay: 1 }).timeScale(1)

startTl.to(startAnimationSvg, {
    opacity: 1,
    duration: 1,
    duration: 0.5
})

if (window.innerWidth <= 900) {
    gsap.set(mainBlock, {
        height: document.querySelector('.main-block__height').getBoundingClientRect().height + 50,
    })

    if (window.innerWidth <= 767) {
        gsap.set(mainBlockVideo, {

            bottom: -(window.innerHeight - document.querySelector('#main-block__animation-path').getBoundingClientRect().height) / 2 + 30
        })
    } else {
        gsap.set(mainBlockVideo, {

            // bottom: -(window.innerHeight - document.querySelector('#main-block__animation-path').getBoundingClientRect().height) / 2 + 55
        })
    }


}


window.addEventListener('load', () => {

    let boundingInfo = document.querySelector('#main-block__animation-path').getBoundingClientRect()

    if (window.innerWidth <= 900) {

        gsap.set(mainBlockVideo, {
            borderRadius: `0 ${window.innerHeight * 60 / 1400}px 0 ${window.innerHeight * 60 / 1400}px`,
            height: boundingInfo.height,
            width: boundingInfo.width,
            top: boundingInfo.top,
        })
        startTl.to(startAnimationSvg, {
            scale: 1,
            duration: 1.5,
            ease: 'power1.inOut'
        }, '>')

        startTl.to(mainBlockVideo, {
            opacity: 1,
            duration: 1,
        }, '>')

        startTl.to(startAnimationSvg, {
            opacity: 0,
            duration: 1
        }, '<+0.5')
        startTl.to('.header', {
            opacity: 1
        }, '>-0.3')

        startTl.to(mainBlockVideo, {
            width: '100%',
            marginLeft: 0,
            marginTop: 0,
            height: '100%',
            top: 0
        }, '>')

        startTl.to(mainBlock, {
            marginTop: document.querySelector('.header').offsetHeight,
            top: 0,
            transform: 'translateY(0%)'
        }, '<')

        startTl.to(mainBlockVideo, {
            position: 'relative',
            top: 0,
            transform: 'translate(0)',
            duration: 1
        }, '>')

        // startTl.to(mainBlockVideo, {
        //     height: 'auto',
        // }, '<')


        startTl.to(mainBlockWrapper, {
            opacity: 1
        }, '<')

        startTl.to([content, body], {
            height: 'auto',
            overflow: 'auto',
            duration: 0
        }, '>')

        startTl.to(startAnimationSvg, {
            display: 'none',
        })
    } else {


        gsap.set(mainBlockVideo, {
            borderRadius: '0 120px 0 120px',
            height: boundingInfo.height,
            width: boundingInfo.width,
            marginTop: boundingInfo.top,
            x: boundingInfo.x,
            marginLeft: 0
        })

        startTl.to(mainBlockVideo, {
            opacity: 1,
            duration: 0.8,
        }, '>')

        startTl.to(startAnimationSvg, {
            opacity: 0,
            duration: 0.8
        }, '<')

        startTl.to('.header', {
            opacity: 1
        }, '>-0.3')

        startTl.to(mainBlock, {
            marginTop: document.querySelector('.header').offsetHeight,
        }, '<')



        startTl.to(mainBlock, {
            height: 700

        }, '>-0.3')

        startTl.to(mainBlockWrapper, {
            opacity: 1
        }, '<')

        startTl.to(mainBlockVideo, {
            width: '100%',
            marginLeft: 0,
            marginTop: 0,
            height: '100%',
            x: 0
        }, '<')

        startTl.to([content, body], {
            height: 'auto',
            overflow: 'auto',
        }, '<')

        startTl.to(startAnimationSvg, {
            display: 'none',
        })

    }

})

// Slider animation
window.addEventListener('load', () => {
  const swiper = new Swiper('.swiper', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      autoplay: {
          delay: 5000,
          disableOnInteraction: false,
      },
      speed: 1500, // Adjust the speed of the slide transition

      // If we need pagination
      // pagination: {
      //     el: '.swiper-pagination',
      // },

      // Navigation arrows
      // navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev',
      // },

      // And if we need scrollbar
      // scrollbar: {
      //     el: '.swiper-scrollbar',
      // },
  });
})

// Next step animation

let nextStepTl = gsap.timeline()
const nextStepFromAnimation = document.querySelectorAll('#next-step_svg path')
const nextStepToAnimation = document.querySelectorAll('#foot_svg polygon')
const nextStepToMorphAnimation = document.querySelectorAll('#next-step_svg-from path')
for (let index = 0; index < nextStepToAnimation.length; index++) {
    nextStepTl.to(nextStepToMorphAnimation[index], {
        duration: 0.35,
        fill: `${getComputedStyle(nextStepFromAnimation[index]).getPropertyValue("fill")}`,
        transform: "translate(-55.64px , -642.21px)",
        morphSVG: nextStepFromAnimation[index],
    }, '<+0.025')
}
gsap.to('#next-step_svg-from', {
    scale: 1,
    rotate: 0
})
ScrollTrigger.create({
    trigger: '.next-step-bar',
    start: () => {
        if (window.innerWidth <= 900) {
            return "top top"
        } else {
            return "top center-=350"
        }
    },
    end: "bottom top-=2000",
    onToggle: (self) => {
        nextStepTl.clear()

        if (self.isActive) {
            for (let index = 0; index < nextStepToAnimation.length; index++) {
                nextStepTl.to(nextStepToMorphAnimation[index], {
                    duration: 0.15,
                    fill: `${getComputedStyle(nextStepToAnimation[index]).getPropertyValue("fill")}`,
                    morphSVG: nextStepToAnimation[index],
                    transform: "translate(0px,  0px)"
                }, '<+0.025')
            }

            gsap.to('#next-step_svg-from', {
                scale: 1.5,
                rotate: 45
            })
        } else {
            for (let index = 0; index < nextStepToAnimation.length; index++) {
                nextStepTl.to(nextStepToMorphAnimation[index], {
                    duration: 0.15,
                    fill: `${getComputedStyle(nextStepFromAnimation[index]).getPropertyValue("fill")}`,
                    transform: "translate(-55.64px , -642.21px)",
                    morphSVG: nextStepFromAnimation[index],
                }, '<+0.025')
            }
            gsap.to('#next-step_svg-from', {
                scale: 1,
                rotate: 0
            })
        }

    }
})

if (window.innerWidth <= 767) {
    ScrollTrigger.create({
        trigger: '.next-step-bar',
        start: () => {
            if (window.innerWidth <= 767) {
                return "top top-=40"
            } else {
                return "top center-=200"
            }
        },
        end: `bottom center+=${window.innerHeight < 800 ? window.innerHeight * 0.9 : window.innerHeight * 0.7}`,
        pin: '.next-step-bar__image'
    })
}
else {
    ScrollTrigger.create({
        trigger: '.next-step-bar',
        start: () => {
            if (window.innerWidth <= 767) {
                return "top top-=40"
            } else {
                return "top center"
            }
        },
        end: `bottom top+=${window.innerHeight * 0.8 - window.innerHeight * 0.35}`,
        onUpdate: (self) => {
            const scrolledFromBottom = self.end - self.scroll();
            if (window.innerWidth > 767) {
                if (window.innerHeight > 900 && scrolledFromBottom <= window.innerHeight * 0.8 - window.innerHeight * 0.5) {
                    gsap.to('#next-step-bar__image-0', {
                        top: `${(window.innerHeight / 2) - (window.innerHeight * 0.8 - window.innerHeight * 0.5 - scrolledFromBottom)}px`,
                        duration: 0
                    })
                } else if (scrolledFromBottom <= window.innerHeight * 0.7 - window.innerHeight * 0.5) {
                    gsap.to('#next-step-bar__image-0', {
                        top: `${(window.innerHeight / 2) - (window.innerHeight * 0.7 - window.innerHeight * 0.5 - scrolledFromBottom)}px`,
                        duration: 0
                    })
                }
            }
        },
        onToggle: (self) => {
            if (self.isActive) {
                gsap.to('#next-step-bar__image-0', {
                    right: (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                    duration: 0.5,
                    ease: 'power1.out'
                })
            } else {
                gsap.to('#next-step-bar__image-0', {
                    top: '50%',
                    right: '-200vw',
                    duration: 0.7,
                    ease: 'power1.in'
                })
            }
        }
    })
}

ScrollTrigger.refresh()


// Employye bar animation


gsap.to('#employee-bar__animation-1-top-text', {
  x: -window.innerWidth * 2
})

gsap.to('#employee-bar__animation-1-bottom-text', {
  x: window.innerWidth * 2
})

gsap.to('#employee-bar__animation-2-top-text', {
  x: -window.innerWidth * 2
})

gsap.to('#employee-bar__animation-2-bottom-text', {
  x: window.innerWidth * 2
})

gsap.to('#employee-bar__animation-3-top-text', {
    x: -window.innerWidth * 2
})

gsap.to('#employee-bar__animation-3-bottom-text', {
    x: window.innerWidth * 2
})

const employeeBarAnimation = document.querySelector('#employee-bar__animation-fixed')


ScrollTrigger.create({
  trigger: '.employee-bar',
  start: `top bottom-=${window.innerHeight * 0.4}`,
  end: "bottom top",
  onUpdate: (self) => {
      const scrolledFromBottom = self.end - self.scroll();
      if (scrolledFromBottom <= window.innerHeight) {
          gsap.to(employeeBarAnimation, {
              top: `${-(window.innerHeight - scrolledFromBottom)}px`,
              duration: 0.01
          })
      }
  },
  onToggle: (self) => {
      if (self.isActive) {
          gsap.to(employeeBarAnimation, {
              opacity: 1,
              pointerEvents: 'all',
              duration: 0.25
          })

          ScrollTrigger.create({
              trigger: '.employee-bar__animation-1-hidden',
              start: `top bottom-=${window.innerHeight * 0.4}`,
              end: "bottom center",
              onToggle: (self) => {
                  if (self.isActive) {
                      gsap.to('#employee-bar__animation-1-top-text', {
                          x: 0,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-1-bottom-text', {
                          x: 0,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-1-image', {
                          opacity: 1,
                          duration: 0.5
                      })
                  } else {
                      gsap.to('#employee-bar__animation-1-top-text', {
                          x: -window.innerWidth * 2,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-1-bottom-text', {
                          x: window.innerWidth * 2,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-1-image', {
                          opacity: 0,
                          duration: 0.5
                      })
                  }

              },
          });


          ScrollTrigger.refresh()
          ScrollTrigger.create({
              trigger: '.employee-bar__animation-2-hidden',
              start: "top center",
              end: "bottom center",
              onToggle: (self) => {
                  if (self.isActive) {


                      gsap.to('#employee-bar__animation-2-top-text', {
                          x: 0,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-2-bottom-text', {
                          x: 0,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-2-image', {
                          opacity: 1,
                          duration: 0.5
                      })

                      // employeeBarAnimation.style.position = 'fixed'
                  } else {
                      gsap.to('#employee-bar__animation-2-top-text', {
                          x: -window.innerWidth * 2,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-2-bottom-text', {
                          x: window.innerWidth * 2,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-2-image', {
                          opacity: 0,
                          duration: 0.5
                      })
                  }

              },
          });

          ScrollTrigger.refresh()
          ScrollTrigger.create({
              trigger: '.employee-bar__animation-3-hidden',
              start: "top center",
              end: "bottom center",
              onToggle: (self) => {
                  if (self.isActive) {


                      gsap.to('#employee-bar__animation-3-top-text', {
                          x: 0,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-3-bottom-text', {
                          x: 0,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-3-image', {
                          opacity: 1,
                          duration: 0.5
                      })

                      // employeeBarAnimation.style.position = 'fixed'
                  } else {
                      gsap.to('#employee-bar__animation-3-top-text', {
                          x: -window.innerWidth * 2,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-3-bottom-text', {
                          x: window.innerWidth * 2,
                          duration: 0.5
                      })

                      gsap.to('#employee-bar__animation-3-image', {
                          opacity: 0,
                          duration: 0.5
                      })
                  }

              },
          });
        //   ScrollTrigger.refresh()
        //   ScrollTrigger.create({
        //       trigger: '.employee-bar__animation-4-hidden',
        //       start: "top center",
        //       end: "bottom top",
        //       onToggle: (self) => {
        //           if (self.isActive) {

        //               gsap.to('#employee-bar__animation-4-bottom-text', {
        //                   right: window.innerWidth <= 900 ? `${window.innerWidth / 10}px` : '50px',
        //                   duration: 0.5
        //               })

        //               gsap.to('#employee-bar__animation-4-image', {
        //                   opacity: 1,
        //                   duration: 0.5
        //               })

        //               // employeeBarAnimation.style.position = 'fixed'
        //           } else {

        //               gsap.to('#employee-bar__animation-4-bottom-text', {
        //                   right: '-300vw',
        //                   duration: 0.5
        //               })

        //               gsap.to('#employee-bar__animation-4-image', {
        //                   opacity: 0,
        //                   duration: 0.5
        //               })
        //           }

        //       },
        //   });

          ScrollTrigger.refresh()
      } else {
          gsap.to(employeeBarAnimation, {
              opacity: 0,
              pointerEvents: 'none'
          })
      }

  },
});


ScrollTrigger.refresh()

// Main scroll animation
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(SplitText)

const defaultPolygon = document.querySelector("#to-hide")

const tl = gsap.timeline();
const finalMainAnimation = gsap.timeline()


function toMorph(image, showAnimation, duration) {

    tl.clear(true);
    finalMainAnimation.clear(true);

    const morphFromSvg = document.querySelector('#morph-from svg');

    if (morphFromSvg) {
        const morphFromPolygonsArray = morphFromSvg.querySelectorAll('path');    
        let animationDuration = duration ? duration : 0.35;
    
        gsap.set(morphFromPolygonsArray, { x: 0, y: 0 })
        gsap.set('#morph-from svg', { rotate: 0 })
    
        let polygonToMorph = 0;
    
        let imagePolygonArray = image.querySelectorAll('polygon');
    
    
        if (imagePolygonArray.length == 0) {
            imagePolygonArray = image.querySelectorAll('path');
        }
        for (let index = morphFromPolygonsArray.length - 1; index >= 0; index -= 1) {
            if (imagePolygonArray[polygonToMorph]) {
                tl.to(morphFromPolygonsArray[index], {
                    duration: animationDuration,
                    fill: `${getComputedStyle(imagePolygonArray[polygonToMorph]).getPropertyValue("fill")}`,
                    morphSVG: imagePolygonArray[polygonToMorph],
                    onComplete: () => {
                        if (!imagePolygonArray[morphFromPolygonsArray.length - index]) {
                            if (image == document.querySelector('#bottom-buttle')) {
    
                                setTimeout(() => {
    
                                    finalMainAnimation.to('#morph-from svg', {
                                        duration: 0.1,
                                        rotate: 5,
                                    })
    
                                    finalMainAnimation.to('#morph-from svg', {
                                        duration: 0.1,
                                        rotate: -5,
                                    })
    
                                    finalMainAnimation.to('#morph-from svg', {
                                        duration: 0.1,
                                        rotate: 5,
                                    })
    
                                    finalMainAnimation.to('#morph-from svg', {
                                        duration: 0.1,
                                        rotate: -5,
                                    })
    
                                    finalMainAnimation.to('#morph-from svg', {
                                        duration: 0.1,
                                        rotate: 5,
                                    })
    
                                    finalMainAnimation.to('#morph-from svg', {
                                        duration: 0.1,
                                        rotate: -5,
                                    })
    
                                    finalMainAnimation.to('#morph-from svg', {
                                        duration: 0.1,
                                        rotate: 5,
                                    })
    
                                    finalMainAnimation.to('#morph-from svg', {
                                        duration: 0.1,
                                        rotate: 0,
                                    })
    
                                    finalMainAnimation.to(['#morph-from-path-17', '#morph-from-path-22', '#morph-from-path-26'], {
                                        x: 250,
                                        y: -250,
                                    })
                                }, 0);
                            }
                        }
                    }
                }, duration ? '<+0.09' : '<+0.025');
    
            } else {
                tl.to(morphFromPolygonsArray[index], {
                    duration: animationDuration,
                    fill: `${getComputedStyle(defaultPolygon).getPropertyValue("fill")}`,
                    morphSVG: defaultPolygon,
                }, '<');
            }
            polygonToMorph++
        }    
    }
}


ScrollTrigger.create({
    trigger: ".all-main-animation-on-scroll",
    start: () => {
        if (window.innerWidth <= 767) {
            return "top bottom"
        } else {
            return "top center"
        }
    },
    end:
        () => {
            if (window.innerWidth <= 767) {
                return "bottom center+=100"
            } else {
                return `bottom center-=${window.innerHeight * 0.2}`
            }
        },

    onToggle: (self) => {
        if (self.isActive) {
            ScrollTrigger.create({
                trigger: "#text_1",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top center-=150"
                    } else {
                        return "top center"
                    }
                },
                end:
                    () => {
                        if (window.innerWidth <= 767) {
                            return "bottom top+=200"
                        } else {
                            return "bottom 50%+=100px"
                        }
                    },
                onToggle: (self) => {

                    if (self.isActive) {
                        toMorph(document.querySelector('#bird'))
                        // Change sides

                        if (window.innerWidth <= 767) {
                            document.querySelector('.animation_wrapper').style.left = `25%`
                            document.querySelector('.animation_wrapper').style.width = `50%`
                            document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                            document.querySelector('.animation_wrapper').style.minWidth = `50%`
                            document.querySelector('.animation_wrapper').style.height = `auto`
                            document.querySelector('.animation_wrapper').style.top = `130px`
                        } else {
                            if (document.querySelector('.animation_wrapper')) {
                                document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`                                
                            }
                        }
                    } else {
                        toMorph(document.querySelector('#to-be-here'))

                    }


                },
            });

            ScrollTrigger.create({
                trigger: "#text_2",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=200"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#rebit'), 1500)

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {
                        if (document.querySelector('.animation_wrapper')) {
                            document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`                            
                        }
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_3",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=200"
                    } else {
                        return "top center"
                    }
                },
                end: () => {
                    if (window.innerWidth <= 767) {
                        return "bottom 50%+=300px"
                    } else {
                        return "bottom 50%+=500px"
                    }
                },
                onToggle: (self) => {
                    toMorph(document.querySelector('#squer'), false, 0.82)
                    gsap.to('.text_3-image', {
                        opacity: 0,
                        duration: 0.5
                    })
                    // Change sides

                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {

                        if (document.querySelector('.animation_wrapper')) {
                            let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width                        
                            document.querySelector('.animation_wrapper').style.left = `${padding}px`                                
                        }
                    }
                },
            });

            if (window.innerWidth >= 767) {
                ScrollTrigger.create({
                    trigger: "#text_3",
                    start: "bottom 50%+=500px",
                    end: "bottom 50%+=100px",
                    onToggle: (self) => {
                        toMorph(document.querySelector('#to-be-here'))
                        gsap.to('.text_3-image', {
                            opacity: 1,
                            duration: 0.5
                        })

                        if (window.innerWidth <= 767) {
                            document.querySelector('.animation_wrapper').style.left = `25%`
                            document.querySelector('.animation_wrapper').style.width = `50%`
                            document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                            document.querySelector('.animation_wrapper').style.minWidth = `50%`
                            document.querySelector('.animation_wrapper').style.height = `auto`
                            document.querySelector('.animation_wrapper').style.top = `130px`
                        } else {
                            if (document.querySelector('.animation_wrapper')) {
                                let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width;
                                document.querySelector('.animation_wrapper').style.left = `${padding}px`;
                            }
                        }
                    }
                });
            }


            ScrollTrigger.create({
                trigger: "#text_4",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=500"
                    } else {
                        return "top center"
                    }
                },
                end:
                    () => {
                        if (window.innerWidth <= 767) {
                            return "top top+=300"
                        } else {
                            return "bottom 50%+=300px"
                        }
                    },
                onToggle: (self) => {
                    toMorph(document.querySelector('#cube-1'))

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {
                        if (document.querySelector('.animation_wrapper')) {
                            document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`                            
                        }
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_4",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=300"
                    } else {
                        return "bottom 50%+=300px"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#cube-2'))

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {
                        if (document.querySelector('.animation_wrapper')) {
                            document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`                            
                        }
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_6",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=200"
                    } else {
                        return "top center-=100"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#window'))

                    // Change sides

                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {
                        if (document.querySelector('.animation_wrapper')) {
                            let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width
                            document.querySelector('.animation_wrapper').style.left = `${padding}px`                                
                        }
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_7",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=200"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#sphere'))
                    gsap.to('.text_12-image', {
                        opacity: 0,
                        duration: 0.5
                    })
                    // Change sides

                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {
                        if (document.querySelector('.animation_wrapper')) {
                            let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width;
                            document.querySelector('.animation_wrapper').style.left = `${padding}px`;
                        }
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_8",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=200"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#heart'), 1500)
                    gsap.to('.text_12-image', {
                        opacity: 0,
                        duration: 0.5
                    })
                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {
                        if (document.querySelector('.animation_wrapper')) {
                            document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`;
                        }
                    }
                },
            });


            ScrollTrigger.create({
                trigger: "#text_9",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=200"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom 50%+=100px",
                onToggle: (self) => {
                    toMorph(document.querySelector('#circle_arrow'), 1500)

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {
                        if (document.querySelector('.animation_wrapper')) {
                            document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`;
                        }
                    }
                },
            });


            ScrollTrigger.create({
                trigger: "#text_10",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=200"
                    } else {
                        return "top center"
                    }
                },
                end: "bottom center",
                onToggle: (self) => {
                    toMorph(document.querySelector('#arrow-up'))

                    // Change sides

                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {
                        if (document.querySelector('.animation_wrapper')) {
                            let padding = window.innerWidth - ((window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2) - document.querySelector('.animation_wrapper').getBoundingClientRect().width
                            document.querySelector('.animation_wrapper').style.left = `${padding}px`                                
                        }
                    }
                },
            });

            ScrollTrigger.create({
                trigger: "#text_11",
                start: () => {
                    if (window.innerWidth <= 767) {
                        return "top top+=200"
                    } else {
                        return "top center"
                    }
                },
                end: () => {
                    if (window.innerWidth <= 767) {
                        return `top top-=${window.innerHeight * 0.2}`
                    } else if (window.innerHeight <= 767) {
                        if (window.innerWidth <= 767) {
                            return `top top-=${window.innerHeight * 0.2}`
                        } else return `bottom center-=${window.innerHeight * 0.2}`
                    } else {
                        return `bottom center-=${window.innerHeight * 0.2}`
                    }
                },
                onToggle: (self) => {
                    toMorph(document.querySelector('#bottom-buttle'))

                    // Change sides
                    if (window.innerWidth <= 767) {
                        document.querySelector('.animation_wrapper').style.left = `25%`
                        document.querySelector('.animation_wrapper').style.width = `50%`
                        document.querySelector('.animation_wrapper').style.maxWidth = `50%`
                        document.querySelector('.animation_wrapper').style.minWidth = `50%`
                        document.querySelector('.animation_wrapper').style.height = `auto`
                        document.querySelector('.animation_wrapper').style.top = `130px`
                    } else {
                        if (document.querySelector('.animation_wrapper')) {
                            document.querySelector('.animation_wrapper').style.left = `${(window.innerWidth - document.querySelector('.container').getBoundingClientRect().width) / 2}px`                            
                        }
                    }

                    if (self.isActive) {
                        toMorph(document.querySelector('#bottom-buttle'))
                    }
                    else if (!self.isActive) {
                        toMorph(document.querySelector('#to-be-here'))
                    }
                },
            });
        } else {
            toMorph(document.querySelector('#to-be-here'))
        }

    }
})
ScrollTrigger.refresh()

// Grey images animtion\

ScrollTrigger.create({
  trigger: ".text-info-bar",
  start: "top bottom",
  end: "bottom top",
  onToggle: (self) => {
      if (self.isActive) {

          ScrollTrigger.create({
              trigger: '#text-info-bar__item-1',
              start: "top top+=300",
              end: "bottom top+=300",
              onToggle: (self) => {

                  if (self.isActive) {
                      gsap.to('#text-info-bar__image-1', {
                          left: window.innerWidth <= 900 ?
                              "50%" :
                              (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                      })
                  } else {
                      gsap.to('#text-info-bar__image-1', {
                          left: '-100%',
                      })
                  }
              },
          });

          ScrollTrigger.refresh()

          ScrollTrigger.create({
              trigger: '#text-info-bar__item-2',
              start: "top top+=300",
              end: "bottom top+=300",
              onToggle: (self) => {

                  if (self.isActive) {
                      gsap.to('#text-info-bar__image-2', {
                          left: window.innerWidth <= 900 ?
                              "50%" :
                              (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                      })
                  } else {
                      gsap.to('#text-info-bar__image-2', {
                          left: '-100%',
                      })
                  }
              },
          });

          ScrollTrigger.refresh()

          ScrollTrigger.create({
              trigger: '#text-info-bar__item-3',
              start: "top top+=300",
              end: "bottom top+=300",
              onToggle: (self) => {

                  if (self.isActive) {
                      gsap.to('#text-info-bar__image-3', {
                          left: window.innerWidth <= 900 ?
                              "50%" :
                              (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                      })
                  } else {
                      gsap.to('#text-info-bar__image-3', {
                          left: '-100%',
                      })
                  }
              },
          });

          ScrollTrigger.refresh()

          ScrollTrigger.create({
              trigger: '#text-info-bar__item-4',
              start: "top top+=300",
              end: "bottom top+=300",
              onToggle: (self) => {

                  if (self.isActive) {
                      gsap.to('#text-info-bar__image-4', {
                          left: window.innerWidth <= 900 ?
                              "50%" :
                              (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                      })
                  } else {
                      gsap.to('#text-info-bar__image-4', {
                          left: '-100%',
                      })
                  }
              },
          });

          ScrollTrigger.refresh()

          ScrollTrigger.create({
              trigger: '#text-info-bar__item-5',
              start: "top top+=300",
              end: () => {
                  if (window.innerWidth <= 900) {
                      return "bottom center"
                  } else {
                      return "bottom 50%+=100"
                  }
              },
              onToggle: (self) => {

                  if (self.isActive) {
                      gsap.to('#text-info-bar__image-5', {
                          left: window.innerWidth <= 900 ?
                              "50%" :
                              (window.innerWidth - document.querySelector('.container').offsetWidth) / 2,
                      })
                  } else {
                      gsap.to('#text-info-bar__image-5', {
                          left: '-100%',
                      })
                  }
              },
          });

          ScrollTrigger.refresh()
      }
  },
});
ScrollTrigger.refresh()

// Larg info animation

const largeInfoItems = document.querySelectorAll('.lagre-info-block__item')

largeInfoItems.forEach(item => {
    ScrollTrigger.create({
        trigger: item,
        start: "top center+=200px",
        onToggle: (self) => {
            gsap.to(item, {
                opacity: 1,
                duration: 1
            });
        },
    });

    ScrollTrigger.refresh()
})


// Number counter
let numberCounterTimeline1 = gsap.timeline()
let numberCounterTimeline2 = gsap.timeline()
let numberCounterTimeline3 = gsap.timeline({ repeat: -1, repeatDelay: 1 })



ScrollTrigger.create({
    trigger: "#number-1-animation",
    start: "top bottom",
    end: "bottom top-=100",
    onToggle: (self) => {
        if (self.isActive) {
            numberCounterTimeline1.clear(true)

            numberCounterTimeline1.to('#number-1-animation', {
                duration: 2,
                innerHTML: 35,
                snap: 'innerHTML',
                repeat: -1,
                repeatDelay: 2
            });
        } else {
            numberCounterTimeline1.clear(true)
            gsap.set('#number-1-animation', {
                duration: 0,
                innerHTML: 0,
                snap: 'innerHTML'
            });
        }
    },
});

ScrollTrigger.refresh()
ScrollTrigger.create({
    trigger: "#number-2-animation",
    start: "top bottom",
    end: "bottom top-=100",
    onToggle: (self) => {

        if (self.isActive) {
            numberCounterTimeline2.clear(true)
            numberCounterTimeline2.to('#number-2-animation', {
                duration: 2,
                innerHTML: 40,
                snap: 'innerHTML',
                repeat: -1,
                repeatDelay: 2
            });
        } else {
            numberCounterTimeline2.clear(true)
            gsap.set('#number-2-animation', {
                innerHTML: 0,
                snap: 'innerHTML'
            });
        }

    },
});
ScrollTrigger.refresh()
let currentValue = 0;

ScrollTrigger.create({
    trigger: "#number-3-animation",
    start: "top bottom",
    end: "bottom top-=100",
    onToggle: (self) => {

        if (self.isActive) {
            numberCounterTimeline3.clear(true)
            numberCounterTimeline3.to('#number-3-animation', {
                duration: 3,
                innerHTML: 99,
                snap: 'innerHTML',
            })

            numberCounterTimeline3.to('#number-3-animation-wrapper', {
                opacity: 0
            }, '>-2')
            numberCounterTimeline3.to('#number-4-animation-wrapper', {
                opacity: 1
            }, '<');
        } else {
            numberCounterTimeline3.clear(true)
            gsap.set('#number-3-animation', {
                duration: 0,
                innerHTML: 0,
                snap: 'innerHTML'
            })

            gsap.set('#number-3-animation-wrapper', {
                opacity: 1
            }, '>-1')
            gsap.set('#number-4-animation-wrapper', {
                opacity: 0
            }, '<');
        }

    },
});
ScrollTrigger.refresh()


// Start ScrollTrigger

ScrollTrigger.create({
  trigger: ".all-main-animation-on-scroll",
  start: "top bottom-=350px",
  end: () => {
      if (window.innerHeight >= 1000) {
          return "bottom 100%"
      } else {
          return "bottom top"
      }
  },
  onToggle: (self) => {
      const animationWrapper = document.querySelector('.animation_wrapper');
      if (animationWrapper) {
        if (self.isActive) {
            animationWrapper.style.opacity = `1`;
        } else {
            animationWrapper.style.opacity = `0`;
        }          
      }
  },
});
ScrollTrigger.refresh()

// Bubbles animations

const bubbleTl = gsap.timeline().timeScale(1.5)

let bubbles = document.querySelector('#top-bar-animation-item-bubble')
let bubblesSvgPaths = bubbles.querySelectorAll('svg path')

if (window.innerWidth <= 767) {
    ScrollTrigger.create({
        trigger: "#top-bar-animation-item-bubble",
        start: "top bottom",
        end: 'bottom top',
        onToggle: (self) => {
            if (self.isActive) {
                bubbleTl.to(bubblesSvgPaths[1], {
                    duration: 1,
                    y: 15,
                    x: -13,
                    yoyo: true,
                    repeat: -1,
                    ease: 'linear'
                })

                bubbleTl.to(bubblesSvgPaths[0], {
                    duration: 1.3,
                    y: -15,
                    x: -13,
                    yoyo: true,
                    repeat: -1,
                    ease: 'linear'
                }, '<')

                bubbleTl.to([bubblesSvgPaths[3], bubblesSvgPaths[2]], {
                    duration: 1,
                    y: -40,
                    yoyo: true,
                    repeat: -1,
                    ease: 'linear'

                }, '<')
            } else {
                bubbleTl.clear(true)
                gsap.to(bubblesSvgPaths, {
                    y: 0,
                })
            }
        }
    })
    ScrollTrigger.refresh()
} else {
    bubbles.addEventListener('mouseenter', () => {
        bubbleTl.to(bubblesSvgPaths[1], {
            duration: 1,
            y: 15,
            x: -13,
            yoyo: true,
            repeat: -1,
            ease: 'linear'
        })

        bubbleTl.to(bubblesSvgPaths[0], {
            duration: 1.3,
            y: -15,
            x: -13,
            yoyo: true,
            repeat: -1,
            ease: 'linear'
        }, '<')

        bubbleTl.to([bubblesSvgPaths[3], bubblesSvgPaths[2]], {
            duration: 1,
            y: -40,
            yoyo: true,
            repeat: -1,
            ease: 'linear'

        }, '<')
    })

    bubbles.addEventListener('mouseleave', () => {
        bubbleTl.clear(true)
        gsap.to(bubblesSvgPaths, {
            y: 0,
        })
    })
}

// Heart animations

const heartTl = gsap.timeline().timeScale(1.5)

let heart = document.querySelector('#top-bar-animation-item-heart')
let heartSvg = document.querySelector('#top-bar-animation-item-heart svg')

if (window.innerWidth <= 767) {
    ScrollTrigger.create({
        trigger: "#top-bar-animation-item-heart",
        start: "top bottom",
        end: 'bottom top',
        onToggle: (self) => {
            if (self.isActive) {
                heartTl.to(heartSvg, {
                    duration: 0.3,
                    scale: 1.08,
                    repeat: -1,
                    repeatDelay: 1.3
                })
                heartTl.to(heartSvg, {
                    duration: 0.3,
                    scale: 1,
                    repeat: -1,
                    repeatDelay: 1.3
                })
                heartTl.to(heartSvg, {
                    duration: 0.3,
                    scale: 1.09,
                    repeat: -1,
                    repeatDelay: 1.3
                })

                heartTl.to(heartSvg, {
                    duration: 0.3,
                    scale: 1,
                    repeat: -1,
                    repeatDelay: 1.3
                })
            } else {
                heartTl.clear(true)
                gsap.to(heartSvg, {
                    scale: 1,
                })
            }
        },
    });
    ScrollTrigger.refresh()

} else {
    heart.addEventListener('mouseenter', () => {
        heartTl.to(heartSvg, {
            duration: 0.3,
            scale: 1.08,
            repeat: -1,
            repeatDelay: 1.3
        })
        heartTl.to(heartSvg, {
            duration: 0.3,
            scale: 1,
            repeat: -1,
            repeatDelay: 1.3
        })
        heartTl.to(heartSvg, {
            duration: 0.3,
            scale: 1.09,
            repeat: -1,
            repeatDelay: 1.3
        })

        heartTl.to(heartSvg, {
            duration: 0.3,
            scale: 1,
            repeat: -1,
            repeatDelay: 1.3
        })
    })

    heart.addEventListener('mouseleave', () => {
        heartTl.clear(true)
        gsap.to(heartSvg, {
            scale: 1,
        })
    })
}

// Bottle animations


const bottleTl = gsap.timeline()

let bottle = document.querySelector('#top-bar-animation-item-bottle')
let bottleSvg = document.querySelector('#top-bar-animation-item-bottle svg')

if (window.innerWidth <= 767) {
    ScrollTrigger.create({
        trigger: "#top-bar-animation-item-bottle",
        start: "top bottom",
        end: 'bottom top',
        onToggle: (self) => {
            if (self.isActive) {
                bottleTl.to(bottleSvg, {
                    duration: 0.1,
                    rotate: 7,
                    repeat: -1,
                    repeatDelay: 0.4
                })

                bottleTl.to(bottleSvg, {
                    duration: 0.1,
                    rotate: -7,
                    repeat: -1,
                    repeatDelay: 0.4
                })

                bottleTl.to(bottleSvg, {
                    duration: 0.1,
                    rotate: 7,
                    repeat: -1,
                    repeatDelay: 0.4
                })

                bottleTl.to(bottleSvg, {
                    duration: 0.1,
                    rotate: 0,
                    repeat: -1,
                    repeatDelay: 0.4
                })
            } else {
                bottleTl.clear(true)
                gsap.to(bottleSvg, {
                    scale: 1,
                })
            }
        }
    })
    ScrollTrigger.refresh()
} else {
    bottle.addEventListener('mouseenter', () => {
        bottleTl.to(bottleSvg, {
            duration: 0.1,
            rotate: 7,
            repeat: -1,
            repeatDelay: 0.4
        })

        bottleTl.to(bottleSvg, {
            duration: 0.1,
            rotate: -7,
            repeat: -1,
            repeatDelay: 0.4
        })

        bottleTl.to(bottleSvg, {
            duration: 0.1,
            rotate: 7,
            repeat: -1,
            repeatDelay: 0.4
        })

        bottleTl.to(bottleSvg, {
            duration: 0.1,
            rotate: 0,
            repeat: -1,
            repeatDelay: 0.4
        })

    })

    bottle.addEventListener('mouseleave', () => {
        bottleTl.clear(true)
        gsap.to(bottleSvg, {
            scale: 1,
        })
    })
}


// Rock animations

const rockTl = gsap.timeline().timeScale(1.2)

let rock = document.querySelector('#top-bar-animation-item-rock')
let rockSvg = document.querySelector('#top-bar-animation-item-rock svg')


if (window.innerWidth <= 767) {
    ScrollTrigger.create({
        trigger: "#top-bar-animation-item-rock",
        start: "top bottom",
        end: 'bottom top',
        onToggle: (self) => {
            if (self.isActive) {
                rockTl.to(rockSvg, {
                    duration: 0.15,
                    x: 10,
                    y: 7,
                    rotate: 5,
                    repeat: -1,
                    repeatDelay: 0.55
                })

                rockTl.to(rockSvg, {
                    duration: 0.15,
                    x: -15,
                    y: -20,
                    rotate: -10,
                    repeat: -1,
                    repeatDelay: 0.55
                })

                rockTl.to(rockSvg, {
                    duration: 0.15,
                    x: 15,
                    y: 0,
                    rotate: 5,
                    repeat: -1,
                    repeatDelay: 0.55
                })

                rockTl.to(rockSvg, {
                    duration: 0.15,
                    x: 0,
                    y: 0,
                    rotate: 0,
                    repeat: -1,
                    repeatDelay: 0.55
                })
            } else {
                rockTl.clear(true)
                gsap.to(rockSvg, {
                    x: 0,
                    y: 0
                })
            }
        }
    })
    ScrollTrigger.refresh()
} else {
    rock.addEventListener('mouseenter', () => {
        rockTl.to(rockSvg, {
            duration: 0.15,
            x: 10,
            y: 7,
            rotate: 5,
            repeat: -1,
            repeatDelay: 0.55
        })

        rockTl.to(rockSvg, {
            duration: 0.15,
            x: -15,
            y: -20,
            rotate: -10,
            repeat: -1,
            repeatDelay: 0.55
        })

        rockTl.to(rockSvg, {
            duration: 0.15,
            x: 15,
            y: 0,
            rotate: 5,
            repeat: -1,
            repeatDelay: 0.55
        })

        rockTl.to(rockSvg, {
            duration: 0.15,
            x: 0,
            y: 0,
            rotate: 0,
            repeat: -1,
            repeatDelay: 0.55
        })

    })

    rock.addEventListener('mouseleave', () => {
        rockTl.clear(true)
        gsap.to(rockSvg, {
            x: 0,
            y: 0
        })
    })
}

// Bird animations


const birdTl = gsap.timeline().timeScale(1.2)
const birdTl1 = gsap.timeline().timeScale(1.2)
const birdTl2 = gsap.timeline()

let bird = document.querySelector('#top-bar-animation-item-bird');
let birdSvg = document.querySelector('#top-bar-animation-item-bird svg');
let birdSvgW1 = document.querySelectorAll('#top-bar-animation-item-bird-w-1');
let birdSvgW2 = document.querySelector('#top-bar-animation-item-bird-w-2');


if (window.innerWidth <= 767) {
    ScrollTrigger.create({
        trigger: "#top-bar-animation-item-bird",
        start: "top bottom",
        end: 'bottom top',
        onToggle: (self) => {
            if (self.isActive) {
                birdTl.to(birdSvgW2, {
                    duration: 0.3,
                    scaleX: 0.5,
                    scaleY: 1.2,
                    x: 150,
                    y: -55,
                    rotate: 9,
                    repeat: -1,
                    repeatDelay: 0.3,
                    delay: 0.3
                })

                birdTl.to(birdSvgW2, {
                    duration: 0.3,
                    scaleX: 1,
                    scaleY: 1,
                    x: 0,
                    y: 0,
                    rotate: 0,
                    repeat: -1,
                    repeatDelay: 0.3
                })


                birdTl1.to(birdSvgW1, {
                    duration: 0.3,
                    scaleX: 0.5,
                    scaleY: 1,
                    x: 135,
                    y: -15,
                    rotate: 7,
                    repeat: -1,
                    repeatDelay: 0.3

                })

                birdTl1.to(birdSvgW1, {
                    duration: 0.3,
                    scaleX: 1,
                    scaleY: 1,
                    x: 0,
                    y: 0,
                    rotate: 0,
                    repeat: -1,
                    repeatDelay: 0.3
                })

                birdTl2.to(birdSvg, {
                    y: -20,
                    duration: 0.5,
                    yoyo: true,
                    repeat: -1,
                    ease: 'power1.inOut'
                })
            } else {
                birdTl.clear(true)
                birdTl1.clear(true)
                birdTl2.clear(true)
                gsap.to(birdSvgW1, {
                    scaleX: 1,
                    scaleY: 1,
                    x: 0,
                    y: 0,
                    rotate: 0,
                })
                gsap.to(birdSvgW2, {
                    scaleX: 1,
                    scaleY: 1,
                    x: 0,
                    y: 0,
                    rotate: 0,
                })
                gsap.to(birdSvg, {
                    y: 0,
                    duration: 0.5,
                    ease: 'power1.inOut'
                })
            }
        }
    })
    ScrollTrigger.refresh()
} else {
    bird.addEventListener('mouseenter', () => {
        birdTl.to(birdSvgW2, {
            duration: 0.3,
            scaleX: 0.5,
            scaleY: 1.2,
            x: 150,
            y: -55,
            rotate: 9,
            repeat: -1,
            repeatDelay: 0.3,
            delay: 0.3
        })

        birdTl.to(birdSvgW2, {
            duration: 0.3,
            scaleX: 1,
            scaleY: 1,
            x: 0,
            y: 0,
            rotate: 0,
            repeat: -1,
            repeatDelay: 0.3
        })


        birdTl1.to(birdSvgW1, {
            duration: 0.3,
            scaleX: 0.5,
            scaleY: 1,
            x: 135,
            y: -15,
            rotate: 7,
            repeat: -1,
            repeatDelay: 0.3

        })

        birdTl1.to(birdSvgW1, {
            duration: 0.3,
            scaleX: 1,
            scaleY: 1,
            x: 0,
            y: 0,
            rotate: 0,
            repeat: -1,
            repeatDelay: 0.3
        })

        birdTl2.to(birdSvg, {
            y: -20,
            duration: 0.5,
            yoyo: true,
            repeat: -1,
            ease: 'power1.inOut'
        })

    })

    bird.addEventListener('mouseleave', () => {
        birdTl.clear(true)
        birdTl1.clear(true)
        birdTl2.clear(true)
        gsap.to(birdSvgW1, {
            scaleX: 1,
            scaleY: 1,
            x: 0,
            y: 0,
            rotate: 0,
        })
        gsap.to(birdSvgW2, {
            scaleX: 1,
            scaleY: 1,
            x: 0,
            y: 0,
            rotate: 0,
        })
        gsap.to(birdSvg, {
            y: 0,
            duration: 0.5,
            ease: 'power1.inOut'
        })
    })
}

// Arrow animations


const arrowTl = gsap.timeline()

let arrow = document.querySelector('#top-bar-animation-item-arrow')
let arrowSvg = document.querySelector('#top-arrow-head')

if (window.innerWidth <= 767) {
    ScrollTrigger.create({
        trigger: "#top-bar-animation-item-arrow",
        start: "top bottom",
        end: 'bottom top',
        onToggle: (self) => {
            if (self.isActive) {
                arrowTl.to(arrowSvg, {
                    x: 0,
                    y: 78,
                    scale: 0.8,
                    repeat: -1,
                    duration: 0.5,
                    repeatDelay: 2
                })

                arrowTl.to(arrowSvg, {
                    x: 0,
                    y: 0,
                    scale: 1,
                    repeat: -1,
                    duration: 0.5,
                    repeatDelay: 2
                })

                arrowTl.to(arrowSvg, {
                    x: 0,
                    y: 78,
                    scale: 0.8,
                    repeat: -1,
                    duration: 0.5,
                    repeatDelay: 2
                })

                arrowTl.to(arrowSvg, {
                    x: 0,
                    y: 0,
                    scale: 1,
                    repeat: -1,
                    duration: 0.5,
                    repeatDelay: 2
                })
            } else {
                arrowTl.clear(true)
                gsap.to(arrowSvg, {
                    scale: 1,
                    y: 0,
                    x: 0
                })
            }
        }
    })
    ScrollTrigger.refresh()
} else {
    arrow.addEventListener('mouseenter', () => {

        arrowTl.to(arrowSvg, {
            x: 0,
            y: 78,
            scale: 0.8,
            repeat: -1,
            duration: 0.5,
            repeatDelay: 2
        })

        arrowTl.to(arrowSvg, {
            x: 0,
            y: 0,
            scale: 1,
            repeat: -1,
            duration: 0.5,
            repeatDelay: 2
        })

        arrowTl.to(arrowSvg, {
            x: 0,
            y: 78,
            scale: 0.8,
            repeat: -1,
            duration: 0.5,
            repeatDelay: 2
        })

        arrowTl.to(arrowSvg, {
            x: 0,
            y: 0,
            scale: 1,
            repeat: -1,
            duration: 0.5,
            repeatDelay: 2
        })

    })

    arrow.addEventListener('mouseleave', () => {
        arrowTl.clear(true)
        gsap.to(arrowSvg, {
            scale: 1,
            y: 0,
            x: 0
        })
    })

}

window.addEventListener('load', ()=>{
            // "use strict";
            let camera,
            scene,
            element = document.getElementById(window.innerWidth <= 767 ? 'demo-image--small' : 'demo-image'), // Inject scene into this
            elementImage = element.dataset.img,
            parentElement = element.parentElement, // Inject scene into this
            renderer,
            onPointerDownPointerX,
            onPointerDownPointerY,
            onPointerDownLon,
            onPointerDownLat,
            fov = 70, // Field of View
            isUserInteracting = false,
            lon = 0,
            lat = 0,
            phi = 0,
            theta = 0,
            onMouseDownMouseX = 0,
            onMouseDownMouseY = 0,
            onMouseDownLon = 0,
            onMouseDownLat = 0,
            width = parseFloat(window.getComputedStyle(parentElement).getPropertyValue('width')), // int || window.innerWidth
            height = window.innerWidth <= 767 ? window.innerHeight * 0.5 : window.innerHeight * 0.7, // int || window.innerHeight
            ratio = width / height;
            canvasWidth = width;
            canvasHeight = height;

        let texture = THREE.ImageUtils.loadTexture(elementImage, new THREE.UVMapping(), function () {
            init();
            animate();
        });
        function init() {
            camera = new THREE.PerspectiveCamera(fov, ratio, 1, 1000);
            scene = new THREE.Scene();
            let mesh = new THREE.Mesh(new THREE.SphereGeometry(500, 60, 40), new THREE.MeshBasicMaterial({ map: texture }));
            mesh.scale.x = -1;
            scene.add(mesh);
            renderer = new THREE.WebGLRenderer({ antialias: true });
            renderer.setSize(width, height);
            element.appendChild(renderer.domElement);
            element.addEventListener('mousedown', onDocumentMouseDown, false);
            element.addEventListener('touchstart', onDocumentTouchStart, false);
            element.addEventListener('touchend', onDocumentTouchEnd, false);
            element.addEventListener('touchmove', onDocumentTouchMove, false);
            window.addEventListener('resize', onWindowResized, false);
            // To use the wheel events to scale the image

            // element.addEventListener('mousewheel', onDocumentMouseWheel, false);
            // element.addEventListener('DOMMouseScroll', onDocumentMouseWheel, false);
            onWindowResized(null);

            var canvas = document.createElement('canvas');
            var gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

            // Перевірка, чи вдалося отримати контекст
            if (gl) {
                // Отримання максимального розміру текстури
                var maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
                console.log('Максимальний розмір текстури:', maxTextureSize);
            } else {
                console.error('WebGL не підтримується у вашому браузері.');
            }
        }
        function onWindowResized(event) {
            //    renderer.setSize(window.innerWidth, window.innerHeight);
            //    camera.projectionMatrix.makePerspective(fov, window.innerWidth / window.innerHeight, 1, 1100);
            renderer.setSize(width, height);
            camera.projectionMatrix.makePerspective(fov, ratio, 1, 1100);
        }
        function onDocumentMouseDown(event) {
            if (event.type === 'mousedown' || event.type === 'touchstart') {
                event.preventDefault();
                const clientX = event.type === 'mousedown' ? event.clientX : event.touches[0].clientX;
                const clientY = event.type === 'mousedown' ? event.clientY : event.touches[0].clientY;
                onPointerDownPointerX = clientX;
                onPointerDownPointerY = clientY;
                onPointerDownLon = lon;
                onPointerDownLat = lat;
                isUserInteracting = true;
                element.addEventListener('mousemove', onDocumentMouseMove, false);
                element.addEventListener('touchmove', onDocumentTouchMove, false);
                element.addEventListener('mouseup', onDocumentMouseUp, false);
                element.addEventListener('touchend', onDocumentMouseUp, false);
            }
        }

        function onDocumentMouseMove(event) {
            if (event.type === 'mousemove' || event.type === 'touchmove') {
                const clientX = event.type === 'mousemove' ? event.clientX : event.touches[0].clientX;
                const clientY = event.type === 'mousemove' ? event.clientY : event.touches[0].clientY;
                lon = (clientX - onPointerDownPointerX) * -0.175 + onPointerDownLon;
                // lat = (clientY - onPointerDownPointerY) * -0.175 + onPointerDownLat;

                // Not to see the bottom (there can be the black rectangle)
                if (lat > 0) lat = 0;
            }
        }

        function onDocumentMouseUp(event) {
            if (event.type === 'mouseup' || event.type === 'touchend') {
                isUserInteracting = false;
                element.removeEventListener('mousemove', onDocumentMouseMove, false);
                element.removeEventListener('touchmove', onDocumentTouchMove, false);
                element.removeEventListener('mouseup', onDocumentMouseUp, false);
                element.removeEventListener('touchend', onDocumentMouseUp, false);
            }
        }

        function onDocumentTouchStart(event) {
            // event.preventDefault();

            // Handle single touch only
            if (event.touches.length !== 1) {
                return;
            }

            const touch = event.touches[0];
            onPointerDownPointerX = touch.clientX;
            onPointerDownPointerY = touch.clientY;
            onPointerDownLon = lon;
            onPointerDownLat = lat;
            isUserInteracting = true;
        }
        function onDocumentTouchMove(event) {
            const touch = event.touches[0];
            lon = (touch.clientX - onPointerDownPointerX) * -0.175 + onPointerDownLon;
            lat = (touch.clientY - onPointerDownPointerY) * -0.175 + onPointerDownLat;

            if (lat > 0) lat = 0;

        }
        function onDocumentTouchEnd(event) {
            isUserInteracting = false;
        }

        function onDocumentMouseWheel(event) {
            // WebKit
            if (event.wheelDeltaY) {
                fov -= event.wheelDeltaY * 0.05;
                // Opera / Explorer 9
            } else if (event.wheelDelta) {
                fov -= event.wheelDelta * 0.05;
                // Firefox
            } else if (event.detail) {
                fov += event.detail * 1.0;
            }
            if (fov < 45 || fov > 90) {
                fov = (fov < 45) ? 45 : 90;
            }
            camera.projectionMatrix.makePerspective(fov, ratio, 1, 1100);
        }
        function animate() {
            requestAnimationFrame(animate);
            render();

            // text animation


            if (checkPosition(-67, -23, '+')) showInfoById('demo-image__info-text--1')
            if (checkPosition(-23, 25, '+')) showInfoById('demo-image__info-text--2')
            if (checkPosition(25, 72, '+')) showInfoById('demo-image__info-text--3')
            if (checkPosition(72, 97, '+')) showInfoById('demo-image__info-text--4')
            if (checkPosition(97, 100, '+')) showInfoById('demo-image__info-text--5')
            if (checkPosition(97, 100, '-')) showInfoById('demo-image__info-text--5')
            if (checkPosition(79, 97, '-')) showInfoById('demo-image__info-text--6')
            if (checkPosition(43, 79, '-')) showInfoById('demo-image__info-text--7')
            if (checkPosition(0, 43, '-')) showInfoById('demo-image__info-text--8')
            if (checkPosition(-47, -1, '-')) showInfoById('demo-image__info-text--9')
            if (checkPosition(-85, -47, '-')) showInfoById('demo-image__info-text--10')
            if (checkPosition(-100, -85, '-')) showInfoById('demo-image__info-text--11')
            if (checkPosition(-100, -67, '+')) showInfoById('demo-image__info-text--12')

        }

        function checkPosition(moreZ, lessZ, x) {
            return (camera.position.z >= moreZ && camera.position.z < lessZ && camera.position.x >= 0 && x === '+')
                ||
                (camera.position.z >= moreZ && camera.position.z < lessZ && camera.position.x < 0 && x === '-')
        }

        function render() {

            // To scroll the image without user interaction

            // if (isUserInteracting === false) {
            //     lon += .05;
            // }


            lat = Math.max(-85, Math.min(85, lat));
            phi = THREE.Math.degToRad(90 - lat);
            theta = THREE.Math.degToRad(lon);
            camera.position.x = 100 * Math.sin(phi) * Math.cos(theta);
            camera.position.y = 100 * Math.cos(phi);
            camera.position.z = 100 * Math.sin(phi) * Math.sin(theta);
            // let log = ("x: " + camera.position.x);
            // log = log + ("<br/>y: " + camera.position.y);
            // log = log + ("<br/>z: " + camera.position.z);
            // log = log + ("<br/>fov: " + fov);
            // document.getElementById('log').innerHTML = log;
            camera.lookAt(scene.position);
            renderer.render(scene, camera);

        }

        function showInfoById(id) {
            let allText = document.querySelectorAll('.demo-image__info')

            allText.forEach(text => {
                if (text.id !== id)
                    gsap.to(text, {
                        opacity: 0,
                        scale: 0,
                        y: 70,
                        duration: 0.16,
                        ease: "power1.inOut",
                    }, '<')
            })

            gsap.to(`#${id}`, {
                opacity: 1,
                scale: 1,
                y: 0,
                duration: 0.16,
                ease: "power1.inOut",
            })
        }
})

window.addEventListener('load', ()=>{
    // instrunction animation
    let instAnimTL = gsap.timeline({ repeat: -1 })
    const instContainer = document.querySelector(window.innerWidth <= 767 ? '#image-bar__instruction--small' : '#image-bar__instruction')
    const animContainer = document.querySelector('#demo-image')
    const animContainerSmall = document.querySelector('#demo-image--small')

    const instCursor = document.querySelector(window.innerWidth <= 767 ? '#image-bar__instruction-animation--small' : '#image-bar__instruction-animation')
    const instCursorHand = instCursor?.querySelector('path:first-child');
    const instCursorCircle = instCursor?.querySelector('path:last-child');

    if (instContainer) {
        console.log(canvasWidth);
        instContainer.style.width = `${canvasWidth}px`;
        // instContainer.style.width = '900px';
        instContainer.style.height = `${canvasHeight}px`;
        // instContainer.style.height = '600px';
    }

    const windowWidth = window.innerHeight;

    gsap.set(instCursorCircle, {
        opacity: 0
    })

    instAnimTL.to(instCursor, {
        x: windowWidth > 991 ? 200 : 70,
        duration: 0.5,
        ease: "power1.out"
    })

    instAnimTL.to(instCursor, {
        scale: 0.9,
        duration: 0.2,
        ease: "power1.inOut"
    })

    instAnimTL.to(instCursorCircle, {
        opacity: 1,
    }, '<')

    instAnimTL.to(instCursor, {
        x: windowWidth > 991 ? -200 : -70,
        duration: 1,
        ease: "power1.inOut"
    })

    instAnimTL.to(instCursor, {
        scale: 1,
        duration: 0.2,
        ease: "power1.inOut"
    })

    instAnimTL.to(instCursorCircle, {
        opacity: 0,
    }, '<')

    instAnimTL.to(instCursor, {
        x: 0,
        duration: 0.5,
        ease: "power1.in"
    })


    animContainer?.addEventListener('mousedown', () => {
        gsap.to(instContainer, {
            opacity: 0,
            duration: 0.25,
            ease: "power1.inOut"
        })
    })

    const eventToListen = () => {
        gsap.to(instContainer, {
            opacity: 0,
            duration: 0.25,
            ease: "power1.inOut"
        })
        animContainer.removeEventListener('mousedown', eventToListen)
        animContainer.removeEventListener('touchstart', eventToListen)
        animContainerSmall.removeEventListener('touchstart', eventToListen)
    }


    animContainer?.addEventListener('mousedown', eventToListen)
    animContainer?.addEventListener('touchstart', eventToListen)
    animContainerSmall?.addEventListener('touchstart', eventToListen)
})
// Top links animated click

const topAnimatedLinks = document.querySelectorAll('.link_to_block')

topAnimatedLinks.forEach((e) => {
    const link = e.getAttribute('data-link')

    e.addEventListener('click', () => {
        document.querySelector(link).scrollIntoView({ block: "center", inline: "nearest" });
    })
})
